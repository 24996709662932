<template>
    <div class="showProcess scrolling">
        <a-steps direction="vertical" :current="stepIndex">
            <a-step v-for="(documentNode, documentNodeIndex) in documentNodeListCopy" :key="documentNode.id">
                <template slot="title">
                    <div
                        v-if="
                            documentNode.sort === 100 &&
                            $route.query.todo === 'dealWith' &&
                            documentNodeIndex === stepIndex &&
                            billDetailInfo.recipientStatus === 0
                        "
                    >
                        <span style="margin-right: 10px">
                            {{ documentNode.name }}
                        </span>
                        <a-button size="small" disabled type="primary" v-if="!csr_list || csr_list.length === 0">
                            抄送
                        </a-button>
                        <a-popconfirm
                            v-else
                            placement="topRight"
                            title="确定抄送？"
                            @confirm="() => carbonCopy(documentNode)"
                        >
                            <a-button size="small" type="primary"> 抄送 </a-button>
                        </a-popconfirm>
                    </div>
                    <div v-else>
                        {{ documentNode.name }}
                    </div>
                </template>
                <template slot="description">
                    <div>
                        <template v-if="documentNode.sort === 100 && billDetailInfo.recipientStatus === 0">
                            <div
                                class="left-line left-line-none flex0"
                                v-if="$route.query.todo === 'dealWith' && documentNodeIndex === stepIndex"
                            >
                                <div class="tag-box flex-wrap" style="margin-left: 0; margin-top: 0">
                                    <div v-for="(o1, i1) in csr_list" :key="i1" class="flex0 flex-algin-center">
                                        <a-tag class="tags flex0 flex-algin-center">
                                            <OpenData
                                                :type="o1.type"
                                                :openid="o1.userId || o1.id"
                                                :corpid="o1.corpId"
                                            ></OpenData>
                                            <a-button type="link" icon="close" @click="csr_list.splice(i1, 1)" />
                                        </a-tag>
                                        <a-icon type="arrow-right" class="arrow-right" />
                                    </div>
                                    <a-button
                                        type="primary"
                                        icon="plus"
                                        ghost
                                        class="plus-bt font"
                                        @click="showSelectDialog('csr_list')"
                                    >
                                    </a-button>
                                </div>
                            </div>
                            <div v-else>
                                <div>
                                    <a-tag v-for="node in documentNode.nodes" :key="node.id">
                                        <OpenData
                                            :type="getDataType(node)"
                                            :openid="node.userId || node.deptId"
                                            :corpid="node.corpId"
                                        ></OpenData>
                                    </a-tag>
                                </div>
                            </div>
                        </template>
                        <template v-else>
                            <div
                                class="description-list flex"
                                :class="{ 'flex-start': node.approvalFlag === 2 }"
                                v-for="node in documentNode.nodes"
                                :key="node.id"
                            >
                                <template v-if="node.approvalFlag === 2">
                                    <div v-if="$route.query.todo !== 'dealWith'">
                                        待发起人<OpenData
                                            type="userName"
                                            :openid="node.userId"
                                            :corpid="node.corpId"
                                        ></OpenData
                                        >处理中...
                                    </div>
                                    <div v-else>
                                        发起人处理：
                                        <a-popconfirm
                                            placement="topRight"
                                            title="确认进入下一流程，将不能在下一个流程之前新增审批节点"
                                            @confirm="() => dealNext(documentNode.id, node.id)"
                                        >
                                            <a-button type="primary" size="small" style="margin-right: 10px"
                                                >确认流程，并进入下一流程</a-button
                                            >
                                        </a-popconfirm>
                                        <!-- <a-button
                      :loading="dealNextLoad"
                      @click="dealNext(documentNode.id, node.id)"
                      type="primary"
                      size="small"
                      style="margin-right: 10px"
                      >确认流程，并进入下一流程</a-button
                    > -->
                                        <a-button type="danger" size="small" @click="addNodes(node, documentNode.id)"
                                            >加签</a-button
                                        >
                                    </div>
                                </template>
                                <template v-else>
                                    <div
                                        class="name"
                                        :class="{
                                            check: node.approvalFlag === 1,
                                            clock: node.approvalFlag !== 1 && node.approvalFlag !== 0,
                                        }"
                                    >
                                        <OpenData
                                            :type="getDataType(node)"
                                            :openid="node.userId || node.deptId"
                                            :corpid="node.corpId"
                                        ></OpenData>
                                    </div>
                                    <div
                                        class="status"
                                        v-if="![-1].includes(documentNode.sort)"
                                        :class="{
                                            check:
                                                node.approvalFlag === 1 &&
                                                (documentNode.sort === 100 || node.status === 1),
                                            clock: node.approvalFlag === 1 && node.status === 2,
                                        }"
                                    >
                                        {{ formatApprovalStatus(node.approvalFlag, documentNode.sort, node.status) }}
                                    </div>
                                    <div class="date">{{ formatApprovalTime(node.approvalTime) }}</div>
                                </template>
                            </div>
                        </template>
                    </div>
                </template>
                <template slot="icon">
                    <a-icon type="question-circle" v-if="documentNode.nodeFlag === 0" />
                    <a-icon type="check-circle" style="color: #52c41a" v-else-if="documentNode.nodeFlag === 1" />
                    <a-icon type="clock-circle" style="color: #f5222d" v-else />
                </template>
            </a-step>
        </a-steps>
        <!-- <div class="finish" v-if="billDetailInfo.approvalStatus !== 0">
      <img src="/images/finish.svg" alt="finish" v-if="billDetailInfo.approvalStatus === 2" />
      <img src="/images/rejected.svg" alt="rejected" v-else-if="billDetailInfo.approvalStatus === 3" />
      <img src="/images/going.svg" alt="finish" v-else />
    </div> -->
        <selectLabelPer ref="selectLabelPer" @selectTodoMsg="selectTodoMsg"></selectLabelPer>
        <selectPerson ref="selectPerson" @selectTodoMsg="selectTodoMsg" />
    </div>
</template>
<script>
import { acceptAddNextNode, acceptNextNode, carbonCopyBill } from '@/api/modular/fileStream/documentManage'
import OpenData from '@/views/fileStream/documentManage/weixin/openData'
import selectPersonAndDepart, {
    dealResult,
    getUserListMsg,
} from '@/views/fileStream/documentManage/weixin/selectPerson'
import selectLabelPer from '../../components/selectLabelPer.vue'
import selectPerson from '../../components/selectPerson'
export default {
    components: {
        OpenData,
        selectPerson,
        selectLabelPer,
    },
    props: {
        billDetailInfo: {
            type: Object,
            default() {
                return {
                    documentNodeList: [],
                    approvalStatus: 0,
                    recipientStatus: 0,
                }
            },
        },
        from: {
            type: String,
            default: '',
        },
    },
    computed: {
        documentNodeListCopy() {
            const documentNodeList = this.finnalyDocumentNodeList.slice()
            const cs = documentNodeList.find((j) => j.sort === 100)
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.csr_list = (cs?.nodes || []).map((j) => ({
                corpId: j.corpId,
                [j.deptId ? 'id' : 'userId']: j.deptId || j.userId,
                type: this.getDataType(j),
            }))
            console.log(this.csr_list, 'this.csr_list')
            return documentNodeList
        },
        finnalyDocumentNodeList() {
            const documentNodeList = this.billDetailInfo.documentNodeList.slice()
            documentNodeList.forEach((j) => {
                if (![-1].includes(j.sort)) {
                    this.formatNodeMess(j)
                }
            })
            return documentNodeList
        },
        stepIndex() {
            return this.documentNodeListCopy.findIndex((node) => node.nodeFlag === 0) || 0
        },
    },
    data() {
        return {
            approvalId: '',
            nodeId: '',
            csr_list: [],
            dealNextLoad: false,
            list: [
                {
                    name: '节点',
                    val: [],
                },
            ],
        }
    },
    methods: {
        choosePerson(list, index) {
            const val = [...this[list]]
            const {
                selectedOpenUserIds,
                selectedCorpGroupUserIds,
                selectedDepartmentIds,
                selectedCorpGroupDepartmentIds,
            } = getUserListMsg(list, index, val)
            selectPersonAndDepart({
                selectedOpenUserIds,
                selectedCorpGroupDepartmentIds,
                selectedDepartmentIds,
                selectedCorpGroupUserIds,
            })
                .then((res) => {
                    const { result, resPart } = dealResult(res)
                    this.$set(this, list, [...result, ...resPart])
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        formatNodeMess(node) {
            const arr = []
            const getApprovalTime = (deptUsers) => {
                if (deptUsers.some((l) => l.approvalFlag === 0)) return null
                const times = deptUsers.map((user) => {
                    const timeStr = user.approvalTime.replace(/-/g, '/')
                    const time = new Date(timeStr).getTime()
                    return time
                })
                return this.formatApprovalTime(Math.max(times))
            }
            if (node.deptInfos && node.deptInfos.length > 0) {
                node.deptInfos.forEach((dep) => {
                    arr.push({
                        deptId: dep.deptId,
                        deptUsers: dep.deptUsers,
                        corpId: dep.deptUsers[0].corpId,
                        approvalTime: getApprovalTime(dep.deptUsers),
                        status: Number(!dep.deptUsers.some((l) => l.status === 0)),
                        approvalFlag: Number(!dep.deptUsers.some((l) => l.approvalFlag === 0)),
                    })
                })
            }
            let array = []
            array = array.concat(
                [...node.nodes, ...arr].filter((j) => j.approvalFlag === 1),
                [...node.nodes, ...arr].filter((j) => j.approvalFlag !== 1)
            )
            node.nodes = array
        },
        getDataType(node) {
            return node.deptId ? 'departmentName' : 'userName'
        },
        showSelectDialog(key, index) {
            this.$refs.selectPerson.open({ key, index })
        },
        objectEquail(obj1, obj2) {
            return JSON.stringify(obj1) === JSON.stringify(obj2)
        },
        selectTodoMsg({ type, data }) {
            if (type === 'book') {
                this.choosePerson(data.key, data.index)
            } else if (type === 'label') {
                this.$refs.selectLabelPer.open({ ...data })
            } else if (type === 'labelResult') {
                const { result, key, index } = data
                if (key === 'list') {
                    result.forEach((ele) => {
                        if (!this[key][index].val.some((j) => this.objectEquail(j, ele))) {
                            this[key][index].val.push(ele)
                        }
                    })
                } else {
                    result.forEach((element) => {
                        if (!this[key].some((j) => this.objectEquail(j, element))) {
                            this[key].push(element)
                        }
                    })
                }
            }
        },
        getIdKey(type) {
            return type === 'departmentName' ? 'departmentId' : 'userId'
        },
        carbonCopy({ id }) {
            carbonCopyBill({
                nodeId: id,
                userList: this.csr_list.map(({ userId, corpId, id, type }) => ({
                    [this.getIdKey(type)]: userId || id,
                    corpId,
                })),
            })
                .then((res) => {
                    console.log(res)
                    if (res.code === 200) {
                        this.$message.success('抄送成功')
                        this.$nextTick(() => {
                            this.$router.go(-1)
                        })
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        formatApprovalStatus(approvalFlag, sort, status) {
            if (sort === 99) {
                return approvalFlag ? '已接收' : '待接收'
            }
            if (sort === 100) {
                return approvalFlag ? '已通知' : '待通知'
            }
            if (approvalFlag === 0) {
                return '待审核'
            } else if (approvalFlag === 3) {
                return '同级已审'
            } else if (status === 1) {
                return '审核通过'
            } else {
                return '驳回'
            }
        },
        formatApprovalTime(approvalTime) {
            if (!approvalTime) return ''
            const date = new Date(approvalTime)
            const dateStr = date.toLocaleString()
            return dateStr.replace(/\//g, '-')
        },
        dealNext(id, nodeId) {
            if (this.dealNextLoad) return
            this.dealNextLoad = true
            acceptNextNode({
                billId: this.$route.query.id,
                nodeId: id,
                approvalId: nodeId,
            })
                .then((res) => {
                    if (res.code === 200) {
                        this.$message.success('流程确认成功！')
                        this.$nextTick(() => {
                            this.$router.go(-1)
                        })
                    } else {
                        this.$message.warning(res.message)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
                .finally(() => {
                    this.dealNextLoad = false
                })
        },
        addNodes({ id }, nodeId) {
            this.approvalId = id
            this.nodeId = nodeId
            this.$router.push({
                name: 'mobileCountersign',
                query: {
                    nodeId,
                    approvalId: id,
                    id: this.$route.query.id,
                },
            })
        },
        handleOk() {
            if (this.list.some((j) => !j.val || j.val.length === 0)) {
                this.$message.warning('存在节点没有选择人员！')
                return
            }
            const documentNodeParams = this.list.map((el, index) => ({
                billId: this.$route.query.id,
                sort: index,
                name: el.name,
                approvals: el.val.map((v) => ({ userId: v.userId, corpId: v.corpId })),
            }))
            acceptAddNextNode({
                documentNodeParams,
                approvalId: this.approvalId,
                nodeId: this.nodeId,
            })
                .then((res) => {
                    console.log(res)
                    if (res.code === 200) {
                        this.$message.success('添加成功')
                        this.$nextTick(() => {
                            this.$router.go(-1)
                        })
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
                .finally(() => {
                    this.modelVisible = false
                })
        },
    },
}
</script>
<style lang="less" scoped>
.showProcess {
    .flex {
        display: flex;
        justify-content: space-between;
    }
    .flex0 {
        display: flex;
    }
    .font {
        font-size: 16px;
    }

    .flex-algin-center {
        align-items: center;
        .tags {
            line-height: 1;
        }
    }

    .flex-wrap {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        .arrow-right {
            line-height: 1;
            height: 14px;
        }
    }

    .tag-box {
        width: 100%;
    }

    .description-list {
        align-items: center;
        justify-content: space-between;
        &.flex-start {
            justify-content: flex-start;
        }

        .name {
            font-size: 14px;
            color: #000000;
            margin-right: 20px;
            &.check {
                color: #52c41a;
            }
            &.clock {
                color: #f5222d;
            }
        }
        .status {
            margin-right: 20px;
            &.check {
                color: #52c41a;
            }
            &.clock {
                color: #f5222d;
            }
        }
        .date {
            font-size: 12px;
            color: #3e3d3d;
        }
    }
    .finish {
        text-align: right;
        img {
            width: 100px;
            height: 100px;
        }
    }
}
</style>
